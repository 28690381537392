import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Landing from "./pages/Landing";
import Links from "./pages/Links";
import ApostilasOnline from "./pages/MaterialOnline";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/links" component={Links} />
        <Route exact path="/materialonline" component={ApostilasOnline} />
      </Switch>
    </Router>
  );
};

export default Routes;
