import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import React, { useState } from "react";
import apostila1 from "./../components/img/apostila1.png";
import slide1 from "./../components/img/slide1.png";
import slideeapostila from "./../components/img/slideeapostila.png";
import Footer from "../components/sections/Footer";
import Nav from "../components/sections/Header";

export default function ApostilasOnline() {
  return (
    <>
    
      <Nav />
      <Container maxW={"7xl"} py={{ base: 10, md: 20 }}>
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 10, md: 20 }}
          direction={{ base: "column", md: "row" }}
          textAlign="center"
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "#E5C0AC",
                  zIndex: -1,
                }}
              >
                MS
              </Text>
              <br />
              <Text as={"span"} color={"#E5C0AC"}>
                Apostilas e PDFs
              </Text>
            </Heading>
            <Text color={"gray.200"} fontSize={{ base: "md", sm: "lg" }}>
              Ensine Design de Sobrancelhas sem se preocupar em preparar material didático de excelência.
            </Text>
            <Text
              color={"gray.200"}
              fontSize={{ base: "lg", sm: "lg" }}
              fontWeight="bold"
            >
              Multiplique agora mesmo o seu faturamento.
            </Text>
            
          </Stack>
        </Stack>

        {/* Grid de Apostilas */}
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          gap={6}
          mb={4}
          mt={10}
          justifyItems="center" // Adiciona centralização horizontal
        >
          <GridItem>
            <ApostilaCard
              image={apostila1}
              title="Apostila Design de Sobrancelhas"
              description="Apostila completa para você dar aulas de design de sobrancelhas."
              link="https://chk.eduzz.com/39VJZXEK9R"
            />
          </GridItem>
          <GridItem>
            <ApostilaCard
              image={slide1}
              title="Slides Design de Sobrancelhas"
              description="Slides completos para você dar aulas de design de sobrancelhas."
              link="https://chk.eduzz.com/E9OBJDGQ0B"
            />
          </GridItem>
          <GridItem>
            <ApostilaCard
              image={slideeapostila}
              title="Pacote Apostila + Slides"
              description="Desconto de 20% na compra do pacote de apostila e slides."
              link="https://chk.eduzz.com/40QDZB73WB"
            />
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

const ApostilaCard = ({ image, title, description, link }) => {
  return (
    <Box
      maxW={"320px"}
      w={"full"}
      bg={"gray.700"}
      border={"1px solid"}
      borderColor="gray.500"
      boxShadow={"2xl"}
      rounded={"xl"}
      overflow={"hidden"}
      _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
      transition={"0.3s"}
    >
      <Image
        h={{ base: "180px", md: "210px" }}
        w={"full"}
        src={image}
        objectFit={"cover"}
      />

      <Box p={6} textAlign="center">
        <Stack spacing={0} align={"center"} mb={5}>
          <Heading fontSize={"2xl"} fontWeight={500} color="white">
            {title}
          </Heading>
          <Text color={"gray.400"} >{description}</Text>
        </Stack>

        <Stack direction={"row"} justify={"center"}>
          <Button
            as="a"
            href={link}
            target="_blank"
            rounded={"full"}
            size={"lg"}
            px={6}
            fontSize={{ base: "sm", md: "md" }}
            color={"white"}
            bg={"pink.300"}
            _hover={{ bg: "pink.100", transform: "scale(1.1)" }}
            transition={"all 0.3s ease"}
          >
            Download
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
