import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box, Button, Flex, Stack,
  useColorMode, useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import Logo from "../ui/Logo";

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode();
  // console.log("🚀 ~ file: Header.js ~ line 25 ~ Nav ~ colorMode", colorMode)
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <Logo colorMode={colorMode} />
          </Box>

        
        </Flex>
      </Box>
    </>
  );
}
