import { Avatar, Box, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import MSLogo from "../img/msdesignerrosa.png";

export default function Logo({ colorMode }) {
  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold">
        <Link target={'_blank'} href="https://www.mariasalesdesigner.com/">
           <Image src={MSLogo} alt="MS Designer"  w="180px" h="150px" />
        </Link>
      </Text>
    </Box>
  );
}
